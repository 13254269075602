.orthobuttonhome {
  background-color: rgb(256, 256, 256);
  border: none;
  color: rgb(32, 167, 172);
  padding: 0px 0px;
  text-decoration: none;
  display: inline-block;
  font-size: 50px;
  opacity: 1;
  transition-duration: 0.4s;
  cursor: arrow;
  margin-top: -3px;
  width: 180px;
  z-index: 1;
}

.pathwaybuttonhome {
  background-color: rgb(32, 167, 172);
  border: none;
  color: white;
  padding: 0px 0px;
  text-decoration: none;
  display: inline-block;
  font-size: 50px;
  opacity: 1;
  transition-duration: 0.4s;
  cursor: arrow;
  margin-top: -3px;
  width: 250px;
  z-index: 1;
}

.splice {
  z-index: -1;
  opacity: 1;
  height: "630px";
  width: "auto";
  margin-left: "0px";
}

.homepagesplice {
  height: "auto";
  width: "auto";
  opacity: "1";
  z-index: "1";
}
